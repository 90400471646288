body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.logo-wrapper {
  text-align: left;
  padding: 0;
  z-index: 9999999;
  position: relative;
}

.app-wrapper {
  width: 100%;
  max-width: 1350px;
  margin: auto;
  padding: 20px;
}

.left-wrapper {
  text-align: left;
}

.main-heading h2 {
  color: #2b2b2b;
  font-family: Inter;
  font-size: 52px;
  font-weight: 900;
  line-height: 120%;
}

.input-email-wrapper {
  background: #fdf8f2;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 20px;
  position: relative;
}

.main-wrapper {
  display: flex;
}

.left-wrapper {
  width: 40%;
}

.right-wrapper {
  width: 60%;
}
.error {
  position: absolute;
  top: 60px;
  font-size: 12px;
  color: #d30000;
}
.input-email-wrapper button {
  border: 1px solid #ed7637;
  background: #ed7637;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  height: 40px;
  color: #fff;
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: 300ms;
  border-radius: 50px;
  display: block;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 999999999;
}

.input-email-wrapper input {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #fff;
  height: 40px;
  padding: 10px;
  margin-bottom: 20px;
  z-index: 9999999;
}

.input-email-wrapper label {
  font-size: 14px;
  font-family: roboto;
  margin-bottom: 9px;
}
.app {
  position: relative;
  height: 100vh;
  background-image: url("./bg.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.app::before {
  background-image: url("./left-logo.svg");
  content: "";
  position: absolute;
  top: 0;
  background-size: contain;
  left: 0;
  height: 318px;
  width: 300px;
  background-repeat: no-repeat;
}

.app::after {
  background-image: url("./right-logo.svg");
  content: "";
  position: absolute;
  bottom: 0;
  background-size: contain;
  right: 0;
  height: 318px;
  width: 190px;
  background-repeat: no-repeat;
}
.emaillistiing li {
  margin-bottom: 10px;
}
img.mock {
  width: 100%;
}
.text-wrapper {
  text-align: right;
  padding-right: 35px;
}
button:disabled {
  opacity: 0.6;
}
.success-wrapper {
  text-align: center;
}

.success-wrapper h3 {
  margin: 10px 0 0;
  font-family: "Lato";
  color: #4d4d4d;
  font-size: 18px;
}

.success-wrapper img {
  width: 100%;
  max-width: 50px;
}

@media (max-width: 991px) {
  .right-wrapper {
    display: none;
  }
  .app {
    background-image: none;
  }
  .left-wrapper {
    width: 100%;
    text-align: center;
  }
  .logo-wrapper {
    text-align: center;
  }
}
